<template>
 
 <div class="contailPanelV">
 <div class="intruduce">
   为贯彻落实《新一代人工智能发展规划》（国发〔2017〕35号）和《促进新一代人工智能产业发展三年行动计划（2018-2020年）》
   （工信部科〔2017〕315号）要求，加快推动我国新一代人工智能产业创新发展，聚焦“培育智能产品、突破核心基础、深化发展智能制造、
   构建支撑体系”等重点方向，编制行业应用案例。
 </div>

     <search-item
          label="行业类型"
          :items="caseTypes"
          :currentValue="status1"
          @handleSearchItem="getByType">        
      </search-item>


          <page-list
              @pageChanged="handlePageChange"
              :detailPath="detailPath"
              :title="title"
              :list="patentlist"
              :current="pagination.current" 
              :size="pagination.size"
              :total="pagination.total"
          />
      
  </div>
</template>

<script>
import pageList from "@/components/list/pageTypeList"; //引入内容组件
import searchItem from '@/components/all/searchItem'

export default {
  name: "PatentList",  
  components: {
    pageList, //引入内容组件
    searchItem,
  },
  props: {
    // 当前的页码(2级菜单)
    chinum: {
      typeof: [Number],
      default: 0,
    },
  },
  data() {
    return {
      caseTypes: [],
      status1: '',
      primary: 1, //一级菜单的选中样式
      istrue: true, //刷新页面
      crumblist: [
        {
          name: "首页",
          path: "/home",
          query: {},
        },
        {
          name: "行业应用案例",
          path: "",
          query: {},
        },
      ], //面包屑数组
      detailPath: '/resource/case/detail',
      patentlist: [], //当前数组
      title: '行业应用案例', //当前标题
      pagination: {
        current: 1, //当前页码
        size: 20, //每页条数
        total: 0, //总数
      },
    }
  },
  created() {
    this.getCaseType()
    // console.log(" created  pagination ", this.pagination)
    this.getlist(this.pagination)
  },
  
  mounted() {
    
  },

  methods: {

     getByType(type){
        this.status1 = type
        this.pageIndex = 1
        let param =  this.status1 ? {type: this.status1} : {}
        this.getlist(param)
      },
  
    getCaseType(){
      this.$api.getCaseType().then((res) => {
        console.log("res ", res)
        let caseTypes =  [{label: "全部", value: ''}]
        res.data.records.forEach(item => {
          caseTypes.push( {label: item.dataName, value: item.ranking})
        })
        this.caseTypes = caseTypes
      })
    },
     //   改编页码之后
    handlePageChange(e) {
      // console.log("xxxx standard list page changed event ",  e)
      this.getlist(e);
    },
    // 获取当前数组
    getlist(pagination) {
      var data = {
        status: 1,
        ...pagination,
      };
      // console.log(" get list query ", data)
      this.$api.getCasePage(data).then((res) => {
        // console.log("----------- get list result ", res)
        res.data.records.forEach(item=>{
         let label = this.getLabel(item.type, this.caseTypes );
         item.type=label
         console.log (" label ", label)
        })
        this.patentlist = res.data.records;
        // console.log("   before set pagination ", this.pagination )
        this.pagination.total = res.data.total;
        this.istrue=true
        // console.log("   after set pagination ", this.pagination )
      });
    },
    
    getLabel(value, list) {
      console.log(" ====value ", value)
      console.log(" list ", list)
      
        for(var element of list){
          console.log( element.value )
            if(value == element.value) {
              return element.label
            }
        }
        return value
    },

  },
};
</script>

<style scoped lang="less">
@media screen and (min-width: 1000px) {
  .detailbox {
      min-height: 50vh;
    // 面包屑
    /deep/ .more {
      display: none;
    }
    /deep/ .cardbox {
      padding: 0;
      .cardhead {
        padding-left: 0.375rem;
      }
      .cardcent {
        margin-top: 1rem;
        padding-left: 0.375rem;

        .hangbox {
          height: 1.875rem;
          margin: 0;
        }
      }
    }
  }
}

@media screen and (max-width: 999px) {
  .detailbox {
      min-height: 50vh;
    // 面包屑
    /deep/ .more {
      display: none;
    }
    /deep/ .cardbox {
      padding: 0 .9375rem;
      .cardhead {
        padding-left:.675rem;
      }
      .cardcent {
        .hangbox {
          height: 1.8rem;
          margin: 0;
        }
      }
    }
  }
}
</style>
